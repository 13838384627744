// src/components/Navbar.js
import React from 'react';
import { Box, Flex, HStack, Link, IconButton, useDisclosure, Stack, Image, Button } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Logo from '../images/Tiny Triumphs Hori.png'; // Import the logo

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const handleToggle = () => (isOpen ? onClose() : onOpen());

  return (
    <Box bg="white" px={4} boxShadow="md" position="sticky" top={0} zIndex={10}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack spacing={8} alignItems="center">
          <Box>
            <RouterLink to="/">
              <Image src={Logo} alt="Tiny Triumphs Logo" height="50px" />
            </RouterLink>
          </Box>
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {location.pathname === '/register' ? (
              <>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Home
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    About Us
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Services
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Facilities
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Contact Us
                  </Link>
                </RouterLink>
              </>
            ) : (
              <>
                <ScrollLink to="hero" smooth={true} duration={500} spy={true} offset={-70}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Home
                  </Link>
                </ScrollLink>
                <ScrollLink to="about-us" smooth={true} duration={500} spy={true} offset={-70}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    About Us
                  </Link>
                </ScrollLink>
                <ScrollLink to="our-services" smooth={true} duration={500} spy={true} offset={-70}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Services
                  </Link>
                </ScrollLink>
                <ScrollLink to="our-facilities" smooth={true} duration={500} spy={true} offset={-70}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Facilities
                  </Link>
                </ScrollLink>
                <ScrollLink to="contact-us" smooth={true} duration={500} spy={true} offset={-70}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Contact Us
                  </Link>
                </ScrollLink>
              </>
            )}
          </HStack>
        </HStack>
        <RouterLink to="/register">
          <Button bgColor="#afda2d" color="white" size="md" ml="auto" display={{ base: 'none', md: 'block' }}>
            Join the Waitlist
          </Button>
        </RouterLink>
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={handleToggle}
        />
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {location.pathname === '/register' ? (
              <>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }} onClick={handleToggle}>
                    Home
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }} onClick={handleToggle}>
                    About Us
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }} onClick={handleToggle}>
                    Our Services
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }} onClick={handleToggle}>
                    Our Facilities
                  </Link>
                </RouterLink>
                <RouterLink to="/">
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }} onClick={handleToggle}>
                    Contact Us
                  </Link>
                </RouterLink>
              </>
            ) : (
              <>
                <ScrollLink to="hero" smooth={true} duration={500} spy={true} offset={-70} onClick={handleToggle}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Home
                  </Link>
                </ScrollLink>
                <ScrollLink to="about-us" smooth={true} duration={500} spy={true} offset={-70} onClick={handleToggle}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    About Us
                  </Link>
                </ScrollLink>
                <ScrollLink to="our-services" smooth={true} duration={500} spy={true} offset={-70} onClick={handleToggle}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Services
                  </Link>
                </ScrollLink>
                <ScrollLink to="our-facilities" smooth={true} duration={500} spy={true} offset={-70} onClick={handleToggle}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Our Facilities
                  </Link>
                </ScrollLink>
                <ScrollLink to="contact-us" smooth={true} duration={500} spy={true} offset={-70} onClick={handleToggle}>
                  <Link px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
                    Contact Us
                  </Link>
                </ScrollLink>
                <RouterLink to="/register">
                  <Button colorScheme="teal" size="md" onClick={handleToggle}>
                    Join the Waitlist
                  </Button>
                </RouterLink>
              </>
            )}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Navbar;
