import React from 'react';
import { Box } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="#f7af28" p={4} textAlign="center">
      © 2024 Tiny Triumphs
    </Box>
  );
};

export default Footer;
