// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#e4f8f9',
      100: '#bae8ed',
      200: '#8dd8e0',
      300: '#5fc8d4',
      400: '#36b8c7',
      500: '#1d9ead',
      600: '#157c86',
      700: '#0d595f',
      800: '#043737',
      900: '#001515',
    },
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
});

export default theme;
